// get a query string parameter by name
function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url),
    res
  if (!results) return null
  if (!results[2]) return ''
  try {
    res = decodeURIComponent(results[2].replace(/\+/g, ' '))
  } catch (error) {
    res = ''
  }
  return res
}

//helper function for grabbing cookies by name
function getCookie(name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function tsSource() {
  var ts = getParameterByName('ts')
  if (ts) {
    return '&ts=' + ts
  } else {
    return '&ts='
  }
}

function getCampaignId() {
  var cpid = getParameterByName('cpid')
  if (cpid) {
    return cpid
  } else {
    return ''
  }
}

function affSource() {
  var aff = getParameterByName('a')
  if (aff) {
    return aff
  } else {
    return ''
  }
}

// checks the cookie to see if a redirect should occur based on age of cookie
function sameDomainCookieCheck() {
  var ckData = JSON.parse(getCookie('landerVisit'))
  if (ckData) {
    console.log('Goodbye')
    window.location =
      'https://mortgage.connectedrates.com/ty' +
      ckData.ckprefix +
      '.html?loan_purpose=' +
      ckData.loanPurpose +
      '&credit_rating=' +
      ckData.creditRating +
      tsSource() +
      '-fdp' +
      '&cpid=' +
      getCampaignId() +
      '&a=' +
      affSource()
  } else {
    // do nothing
  }
}

sameDomainCookieCheck()
